import axios from "axios"
export const logOut = (id) => {
    axios.get(process.env.REACT_APP_LARAVEL_URL+'/signOut').then(function(response) {
            console.log(response.data);
            return response.data
        })
        .catch(function(error) {
            console.log(error);
            return error
        });
}
import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Container,
  Collapse,
  NavbarToggler,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Input,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import Notification from "./Notification";
import darkLogo from "../../assets/images/logo-dark.png";
import lightLogo from "../../assets/images/logo-light.png";
import userImage2 from "../../assets/images/user/img-02.jpg";
import jobImage4 from "../../assets/images/featured-job/img-04.png";
import userImage1 from "../../assets/images/user/img-01.jpg";
import jobImage from "../../assets/images/featured-job/img-01.png";
import profileImage from "../../assets/images/profile.jpg";

import UserContext from "./../../context/UserContext";
import { signIn } from "../../api/auth/signIn";

const NavBar = (props) => {
  const { user, saveUser, signOut } = useContext(UserContext);
  const [signInModal, setSignInModal] = useState(false);
  const openSignInModal = () => setSignInModal(!signInModal);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [home, setHome] = useState(false);
  const [company, setCompany] = useState(false);
  const [pages, setPages] = useState(false);
  const [blog, setBlog] = useState(false); /* 
  //Notification Dropdown
  const [notification, setNotification] = useState(false);
  const dropDownnotification = () => setNotification((prevState) => !prevState); */
  //user Profile Dropdown
  const [userProfile, setUserProfile] = useState(false);
  const dropDownuserprofile = () => setUserProfile((prevState) => !prevState);
  //scroll navbar
  const [navClass, setnavClass] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });
  function scrollNavigation() {
    var scrollup = window.pageYOffset;
    if (scrollup > 0) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  //menu activation
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    var matchingMenuItem = null;
    var ul = document.getElementById("navbarCollapse");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement.parentElement.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }
  const afterSignUp = () => {
    openSignInModal();
  };
  const failSignUp = () => {
    setAlert(true);
  };
  const handleSignUp = (event) => {
    event.preventDefault();
    signIn(email, password, saveUser, afterSignUp, failSignUp);
  };

  return (
    <React.Fragment>
      <nav
        className={"navbar navbar-expand-lg fixed-top sticky p-0 " + navClass}
        id="navigation"
        style={{ backgroundColor: "#280F63" }}
      >
        <Container fluid className="custom-container">
          <Link className="navbar-brand text-dark fw-bold me-auto" to="/">
            <img
              src={darkLogo}
              alt=""
              className="logo-dark"
              style={{ height: "70px", width: "90px" }}
            />
            <img src={lightLogo} height="22" alt="" className="logo-light" />
          </Link>
          <div>
            <NavbarToggler
              className="me-3"
              type="button"
              onClick={() => toggle()}
            >
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
          </div>
          <Collapse
            isOpen={isOpen}
            className="navbar-collapse"
            id="navbarCollapse"
            style={{ backgroundColor: "#280F63" }}
          >
            <ul className="navbar-nav mx-auto navbar-center">
              <NavItem>
                <Link className="nav-link" to="/" style={{ color: "#FFFFFF" }}>
                  Accueil
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to="/aboutus"
                  style={{ color: "#FFFFFF" }}
                >
                  À PROPOS
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to="/services"
                  style={{ color: "#FFFFFF" }}
                >
                  Services
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to="/faqs"
                  style={{ color: "#FFFFFF" }}
                >
                  Faqs
                </Link>
              </NavItem>
              {/* <NavItem className="dropdown dropdown-hover">
                                <NavLink to="/#" id="homedrop" className="arrow-none" onClick={() => setHome(!home)}>
                                    Home <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: home })} aria-labelledby="homedrop">
                                    <li><Link className="dropdown-item" to="/">Home 1</Link></li>
                                    <li><Link className="dropdown-item" to="/layout2">Home 2</Link></li>
                                    <li><Link className="dropdown-item" to="/layout3">Home 3</Link></li>
                                </ul>
                            </NavItem>
                            <NavItem className="dropdown dropdown-hover">
                                <NavLink to="/#" id="jobsdropdown" role="button" onClick={() => setCompany(!company)}>
                                    Company <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: company })} aria-labelledby="jobsdropdown">
                                    <li><Link className="dropdown-item" to="/aboutus">About Us</Link></li>
                                    <li><Link className="dropdown-item" to="/services">Services</Link></li>
                                    <li><Link className="dropdown-item" to="/team">Team</Link></li>
                                    <li><Link className="dropdown-item" to="/pricing">Pricing</Link></li>
                                    <Link className="dropdown-item" to="/privacyandpolicy">Priacy & Policy</Link>
                                    <li><Link className="dropdown-item" to="/faqs">Faqs</Link></li>
                                </ul>
                            </NavItem>
                            <li className="nav-item dropdown dropdown-hover">
                                <Link to="/#" id="pagesdoropdown" className="nav-link dropdown-toggle arrow-none" onClick={() => setPages(!pages)}>
                                    Pages
                                    <div className="arrow-down"></div>
                                </Link>
                                <div className={classname("dropdown-menu dropdown-menu-lg dropdown-menu-center", { show: pages })} aria-labelledby="pagesdoropdown">
                                    <Row>
                                        <Col lg={4}>
                                            <span className="dropdown-header">Jobs</span>
                                            <div>
                                                <Link className="dropdown-item" to="/joblist">Job List</Link>
                                                <Link className="dropdown-item" to="/joblist2">Job List-2</Link>
                                                <Link className="dropdown-item" to="/jobgrid">Job Grid</Link>
                                                <Link className="dropdown-item" to="/jobgrid2">Job Grid-2</Link>
                                                <Link className="dropdown-item" to="/jobdetails">Job Details</Link>
                                                <Link className="dropdown-item" to="/jobscategories">Jobs Categories</Link>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <span className="dropdown-header">Candidates / Companys</span>
                                            <div>
                                                <Link className="dropdown-item" to="/candidatelist">Candidate List</Link>
                                                <Link className="dropdown-item" to="/candidategrid">Candidate Grid</Link>
                                                <Link className="dropdown-item" to="/candidatedetails">Candidate Details</Link>
                                                <Link className="dropdown-item" to="/companylist">Company List</Link>
                                                <Link className="dropdown-item" to="/companydetails">Company Details</Link>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <span className="dropdown-header">Extra Pages</span>
                                            <div>
                                                <Link className="dropdown-item" to="/signup">Sign Up</Link>
                                                <Link className="dropdown-item" to="/signin">Sign In</Link>
                                                <Link className="dropdown-item" to="/signout">Sign Out</Link>
                                                <Link className="dropdown-item" to="/resetpassword">Reset Password</Link>
                                                <Link className="dropdown-item" to="/comingsoon">Coming Soon</Link>
                                                <Link className="dropdown-item" to="/error404">404 Error</Link>
                                                <Link className="dropdown-item" to="/components">Components</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </li>
                            <NavItem className="dropdown dropdown-hover">
                                <NavLink to="/#" id="productdropdown" role="button" data-bs-toggle="dropdown" onClick={() => setBlog(!blog)}>
                                    Blog
                                    <div className="arrow-down"></div>
                                </NavLink>
                                <ul className={classname("dropdown-menu dropdown-menu-center", { show: blog })} aria-labelledby="productdropdown">
                                    <li><Link className="dropdown-item" to="/blog">Blog</Link></li>
                                    <li><Link className="dropdown-item" to="/bloggrid">Blog Grid</Link></li>
                                    <li><Link className="dropdown-item" to="/blogmodern">Blog Modern</Link></li>
                                    <li><Link className="dropdown-item" to="/blogmasonary">Blog Masonry</Link></li>
                                    <li><Link className="dropdown-item" to="/blogdetails">Blog details</Link></li>
                                    <li><Link className="dropdown-item" to="/blogauther">Blog Author</Link></li>
                                </ul>
                            </NavItem> */}
              <NavItem>
                <Link
                  className="nav-link"
                  to="/contact"
                  style={{ color: "#FFFFFF" }}
                >
                  Contact
                </Link>
              </NavItem>
            </ul>
          </Collapse>

          <ul className="header-menu list-inline d-flex align-items-center mb-0">
            {user.length !== 0 ? (
              <React.Fragment>
                <Notification />
                <Dropdown
                  onClick={() => setUserProfile(!userProfile)}
                  isOpen={userProfile}
                  toggle={dropDownuserprofile}
                  className="list-inline-item"
                >
                  <DropdownToggle
                    to="#"
                    className="header-item"
                    id="userdropdown"
                    type="button"
                    tag="a"
                    aria-expanded="false"
                  >
                    <img
                      src={process.env.REACT_APP_LARAVEL_URL + "/public/" + user.photo}
                      alt="mdo"
                      width="35"
                      height="35"
                      className="rounded-circle me-1"
                    />{" "}
                    <span
                      className="d-none d-md-inline-block fw-medium"
                      style={{ color: "#FFFFFF" }}
                    >
                      Hi,
                      <span className="text-capitalize">{user.firstName}</span>
                    </span>
                  </DropdownToggle>

                  <DropdownMenu
                    className="dropdown-menu-end"
                    aria-labelledby="userdropdown"
                    end
                  >
                    {/*  <li><Link className="dropdown-item" to="/managejobs">Manage Jobs</Link></li>
                                <li><Link className="dropdown-item" to="/bookmarkjobs">Bookmarks Jobs</Link></li> */}
                    {user.userType === "user" ? (
                      <React.Fragment>
                        <li>
                          <Link className="dropdown-item" to="/myprofile">
                            Mon profil
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/jobList2">
                            Mes Services
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/jobList">
                            Les services demandés
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/ServiceListAccepte"
                          >
                            Les services acceptés
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/ServiceListRefuse"
                          >
                            Les services refusés
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <li>
                          <Link className="dropdown-item" to="/adminServices">
                            Liste des services
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/adminUsers">
                            List des utilisateurs
                          </Link>
                        </li>
                      </React.Fragment>
                    )}
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/signout"
                        onClick={() => {
                          signOut();
                        }}
                      >
                        Déconnexion
                      </Link>
                    </li>
                  </DropdownMenu>
                </Dropdown>
              </React.Fragment>
            ) : (
              <div>
                <button
                  onClick={openSignInModal}
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#8DD253" }}
                >
                  <span role="button" className=" fw-medium fs-13 ">
                    <i className="uil uil-lock"></i>
                    Se connecter
                  </span>
                </button>
                <Modal
                  isOpen={signInModal}
                  toggle={openSignInModal}
                  role="dialog"
                  centered
                >
                  <ModalBody className="p-5">
                    <div className="position-absolute end-0 top-0 p-3">
                      <button
                        type="button"
                        className="btn-close"
                        onClick={openSignInModal}
                      ></button>
                    </div>
                    <div className="auth-content">
                      <div className="w-100">
                        <div className="text-center mb-4">
                          <h5>Se connecter</h5>
                          <p className="text-muted">
                            Connectez-vous et accédez à toutes les
                            fonctionnalités de Human AI services
                          </p>
                          {alert && (
                            <div className="alert  bg-soft-danger" role="alert">
                              Échec de la connexion. Veuillez vérifier vos
                              identifiants et réessayer.
                            </div>
                          )}
                        </div>
                        <Form onSubmit={handleSignUp} className="auth-form">
                          <FormGroup className="mb-3">
                            <Label htmlFor="emailInput" className="form-label">
                              Email
                            </Label>
                            <Input
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              className="form-control"
                              id="emailInput"
                              placeholder="Entrez votre adresse e-mail"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <label
                              htmlFor="passwordInput"
                              className="form-label"
                            >
                              Mot de passe
                            </label>
                            <Input
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              type="password"
                              className="form-control"
                              id="passwordInput"
                              placeholder="Mot de passe"
                            />
                          </FormGroup>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary w-100"
                            >
                              Inscription
                            </button>
                          </div>
                        </Form>
                        <div className="mt-3 text-center">
                          <p className="mb-0">
                            Pas encore membre ?
                            <Link
                              to="/signup"
                              className="form-text text-primary text-decoration-underline"
                            >
                              Inscrivez-vous
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            )}
          </ul>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default withRouter(NavBar);

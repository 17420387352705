import React from "react";
import Routes from "./Routes/index";

//import Custom Style scss
import "./assets/scss/themes.scss";
import { UserProvider } from "./context/UserContext";

const App = () => {
  return (
    <React.Fragment>
      <UserProvider>
        <Routes />
      </UserProvider>
    </React.Fragment>
  );
};

export default App;

import axios from "axios"
export const signIn = (email, password, saveUser, afterSignUp, failSignUp) => {
    axios.post(process.env.REACT_APP_LARAVEL_URL+'/custom-login', {
            email,
            password
        })
        .then(function(response) {
            console.log(response.data);
            if (response.data.success) {
                saveUser(response.data)
                afterSignUp()
            } else {
                failSignUp()
            }

        })
        .catch(function(error) {
            console.log(error);
        });
}
import  axios from "axios";

export const getNotification = ( id,setNotificationList,setUnreadNotification) => {
     axios
        .get(process.env.REACT_APP_LARAVEL_URL+`/serviceMessage/${id}`
        )
        .then(function(response) {
            /* console.log(response.data); */
             setNotificationList(response.data.messages)
                setUnreadNotification(response.data.unreadCount)
        })
        .catch(function(error) {
            console.log(error);
        });

}

export const markAsRead = ( id) => {
    axios
        .get(process.env.REACT_APP_LARAVEL_URL+`/markAsRead/${id}`
        )
        .then(function(response) {
            console.log(response.data);
        })
        .catch(function(error) {
            console.log(error);
        });
}

import {  createContext,useState } from "react";
import {logOut} from"../api/auth/signOut"
const UserContext =createContext();


export  function UserProvider({children}) {
    const [user, setUser] = useState(window.localStorage.getItem('user')?JSON.parse(window.localStorage.getItem('user')):[]);
    const [show,setShow]=useState(user.length>0?true:false)
    const saveUser=(user)=>{
        setUser(user)
        window.localStorage.setItem('user', JSON.stringify(user));
    }
    const signOut = () => {
        window.localStorage.removeItem('user');
        logOut();
        window.location.reload();
      };
      
    return(
        <UserContext.Provider value={{user,setUser,saveUser,signOut,show,setShow}}>{children}</UserContext.Provider>
    )
}

export default UserContext;

import React, { useState, useContext, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import UserContext from "./../../context/UserContext";

import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import toast, { Toaster } from "react-hot-toast";
import { getNotification,markAsRead } from "../../api/services/notificationMessage";
import moment from "moment";
const Notification = () => {
  const [notification, setNotification] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const dropDownnotification = () => {setNotification((prevState) => !prevState);
    setUnreadNotification(0)
    markAsRead(user.id)
  }
  const { user } = useContext(UserContext);
  // Custom hook to handle the useChannel logic
  configureAbly({
    key: "FdVH1Q.zT-cwQ:Su1O_bvvNNsNAXI8CJiiMM103H4_9-Tc-jMOkFRJSdY",
    clientId: user.id.toString(),
  });

  useEffect(() => {
    getNotification(user.id, setNotificationList,setUnreadNotification);
  }, []);

  const [channel] = useChannel("serviceAI", user.id.toString(), (message) => {
    getNotification(user.id, setNotificationList,setUnreadNotification);
    console.log("noti", message);
    toast.success(
      "Vous avez reçu un nouveau message concernant le service " +
        message.data.title +
        " !"
    );
  });
  return (
    <div>
      <React.Fragment>
        <Toaster
          containerStyle={{
            top: 70,
            right: 40,
          }}
        />
        <Dropdown
          isOpen={notification}
          toggle={dropDownnotification}
          className="list-inline-item  me-4 "
        >
          <DropdownToggle
            href="#"
            className="header-item noti-icon position-relative"
            id="notification"
            type="button"
            tag="a"
          >
            <i className="mdi mdi-bell fs-22 text-white"></i>
            <div className="count position-absolute">{unreadNotification>9?"9+":unreadNotification}</div>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-sm dropdown-menu-end p-0"
            aria-labelledby="notification"
            end
          >
            <div className="notification-header border-bottom bg-light">
              <h6 className="mb-1"> Notification </h6>
              <p className="text-muted fs-13 mb-0">
                Vous avez {unreadNotification} nouvelles notifications
              </p>
            </div>
            <div className="notification-wrapper dropdown-scroll">
              {notificationList.map((notification) => (
                <Link
                  to={`/jobdetails?id_=${notification.serviceId}`}
                  className="text-dark notification-item d-block active"
                  key={notification.time}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                        <i className="uil uil-user-check"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1 fs-14">message concernant le service {notification.title}</h6>
                      <p className="mb-0 fs-12 text-muted">
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        <span>{moment(notification.time, "YYYY-MM-DD HH:mm:ss").fromNow()}</span>

                      </p>
                    </div>
                  </div>
                </Link>
              ))}
              {/* <Link
                        to="#"
                        className="text-dark notification-item d-block active"
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                              <i className="uil uil-user-check"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">
                              22 verified registrations
                            </h6>
                            <p className="mb-0 fs-12 text-muted">
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              <span>3 min ago</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="#"
                        className="text-dark notification-item d-block"
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <img
                              src={userImage2}
                              className="rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">James Lemire</h6>
                            <p className="text-muted fs-12 mb-0">
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              <span>15 min ago</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="#"
                        className="text-dark notification-item d-block"
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <img
                              src={jobImage4}
                              className="rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">
                              Applications has been approved
                            </h6>
                            <p className="text-muted mb-0 fs-12">
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              <span>45 min ago</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="#"
                        className="text-dark notification-item d-block"
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <img
                              src={userImage1}
                              className="rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">Kevin Stewart</h6>
                            <p className="text-muted mb-0 fs-12">
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              <span>1 hour ago</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="#"
                        className="text-dark notification-item d-block"
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <img
                              src={jobImage}
                              className="rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-15">Creative Agency</h6>
                            <p className="text-muted mb-0 fs-12">
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              <span>2 hour ago</span>
                            </p>
                          </div>
                        </div>
                      </Link> */}
            </div>
            <div className="notification-footer border-top text-center">
              <Link className="primary-link fs-13" to="/jobList">
                <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                <span>View More..</span>
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </div>
  );
};

export default Notification;
